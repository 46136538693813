<template>
  <div class="sms-module">
    <div class="row mt-4">
      <h1 class="display-4">SMS Sender</h1>
    </div>

    <div class="row mt-6">
      <div class="col-sm groups-content">
        <div id="accordion-bulk">
          <div class="card">
            <div class="card-header" id="heading-bulk">
              <h5
                  class="mb-0"
                  data-toggle="collapse"
                  :data-target="'#collapse-bulk'"
                  aria-expanded="false"
                  aria-controls="collapse-bulk">
                <feather type="arrow-down-circle" class="align-middle"/>
                <span class="align-middle"> CSV File</span>
              </h5>
            </div>

            <div id="collapse-bulk" class="collapse" aria-labelledby="heading-bulk" :data-parent="'#accordion-bulk'">
              <div class="card-body">
                <div class="row">
                  <span class="mr-2 d-flex align-items-center">
                    Import a two column CSV with phone numbers and text messages
                  </span>
                </div>
                <div class="row mt-4 d-flex align-items-center">
                  <input ref="file" type="file" name="file"/>
                  <button class="btn-success btn-lg px-5" @click="sendFile">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-6">
      <div class="col-sm groups-content">
        <div id="accordion-multiple">
          <div class="card">
            <div class="card-header" id="heading-multiple">
              <h5
                  class="mb-0"
                  data-toggle="collapse"
                  :data-target="'#collapse-multiple'"
                  aria-expanded="false"
                  aria-controls="collapse-multiple">
                <feather type="arrow-down-circle" class="align-middle"></feather>
                <span class="align-middle"> Multiple</span>
              </h5>
            </div>

            <div
                id="collapse-multiple"
                class="collapse"
                aria-labelledby="heading-multiple"
                :data-parent="'#accordion-multiple'">
              <div class="card-body">
                <div class="row">
                  <span class="mr-2 d-flex align-items-center"> Send a single message to multiple phone numbers </span>
                </div>
                <div class="row mt-4 d-flex align-items-center">
                  <div class="col-7 border-right">
                    <textarea
                        ref="smsArea"
                        name="sms-area"
                        id="sms-area"
                        class="form-control"
                        rows="10"
                        placeholder="Type your message here..."></textarea>
                  </div>
                  <div class="col-5">
                    <div class="row align-items-center">
                      <textarea
                          ref="phoneArea"
                          name="phone-area"
                          id="phone-area"
                          class="form-control"
                          rows="5"
                          placeholder="Paste phone numbers here..."
                          @input="onAddPhoneNumbers"
                          @paste="onAddPhoneNumbers"></textarea>
                    </div>
                    <div class="row text-center">
                      <button class="btn-success btn-lg px-5" @click="sendMultiple">Send</button>
                    </div>
                  </div>
                  <div class="col-3 text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal" backdrop="static" :keyboard="false">
      <template v-slot:modal-title>
        <b>Progress</b>
        <span v-if="running" class="ml-2 spinner-border"/>
        <feather v-else type="check" class="ml-2"/>
      </template>
      <h5 class="ml-3">Errors: {{ errors.length }}</h5>
      <div class="row ml-3" v-show="errors.length">
        <div class="error-list col-xl-12">
          <div class="row" v-for="(error, i) in errors" :key="i">
            <span class="col-5 col-xl-3">{{ error.phoneNumber }}</span>
            <span class="col-7 col-xl-9">{{ error.message }}</span>
          </div>
        </div>
        <div class="error-toolbar col-xl-12 mt-2 px-0">
          <feather @click="downloadErrors" rel="tooltip" title="Download errors as CSV" type="download"/>
          <feather @click="copyErrors" rel="tooltip" title="Copy errors to clipboard" type="clipboard"/>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      running: false,
      errors: [],
    };
  },
  computed: {
    errorsAsCSV() {
      return this.errors.reduce(
        (acc, { phoneNumber, message }) => `${acc}\n"${phoneNumber}","${message}"`,
        'phoneNumber,error',
      );
    },
  },
  methods: {
    sendFile() {
      const [ file ] = this.$refs.file.files;
      if (!file) {
        this.$noty.error('You need to select a file first!');
        return;
      }

      const isTypeCSV = file.type === 'text/csv';
      const isWindowsCSV = file.type === 'application/vnd.ms-excel' && file.name.endsWith('.csv');

      if (!isTypeCSV && !isWindowsCSV) {
        this.$noty.error('File must be a CSV!');
        return;
      }

      if (1024 * 1024 < file.size) {
        this.$noty.error('File must not exceed 1MB!');
        return;
      }

      this.send(file);
    },
    sendMultiple() {
      const text = this.$refs.smsArea.value;
      if (!text) {
        this.$noty.error('No text to send.');
        return;
      }

      const phoneNumbers = this.$refs.phoneArea.value;
      if (!phoneNumbers) {
        this.$noty.error('No phone numbers added.');
        return;
      }

      const data = phoneNumbers.split('\n').reduce((acc, phone) => `${acc}"${phone}","${text}"\n`, '');
      const ia = new Uint8Array(data.length);
      for (let i = 0; i < data.length; i++) {
        ia[i] = data.charCodeAt(i);
      }

      this.send(new Blob([ ia ], { type: 'text/csv' }));
    },
    async send(blob) {
      if (this.running) return;
      this.running = true;
      this.errors = [];

      const formData = new FormData();
      formData.append('file', blob);

      const reject = e => {
        let status;
        try {
          const { status: responseStatus } = e.response;

          status = responseStatus;
        } catch (ignored) {
          status = 500;
        }

        if (status === 400) {
          const errors = e.response.data.map(err => `* ${err.message}`);
          this.$noty.warning(errors.join('<br>'));
          return;
        }

        this.$noty.error('Unknown error.<br>Please try again later.');
      };

      this.$bvModal.show('modal');

      await axios
        .post('v1/sms/bulk', formData)
        .then(({ data }) => data.id)
        .then(this.checkProgress)
        .catch(reject)
        .finally(() => {
          this.running = false;
        });
    },
    checkProgress(id) {
      return new Promise(ok => {
        const interval = setInterval(() => {
          const finish = () => {
            clearInterval(interval);
            ok();
          };
          const resolve = res => {
            if (!res || !res.data) {
              finish();
              return;
            }

            this.errors = res.data.errors;

            if (res.data.done) {
              finish();
            }
          };

          axios.get(`v1/sms/bulk/${id}`).then(resolve).catch(finish);
        }, 1500);
      });
    },
    downloadErrors() {
      const a = document.createElement('a');
      a.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(this.errorsAsCSV)}`);
      a.setAttribute('download', 'errors.csv');
      a.style.display = 'none';

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    copyErrors() {
      if (!navigator || !navigator.clipboard) {
        this.$noty.error('Browser feature unsupported.');
        return;
      }

      navigator.clipboard
        .writeText(this.errorsAsCSV)
        .then(() => this.$noty.success('Copied successfully.'))
        .catch(e => this.$noty.error(e.message || 'Unknown error.'));
    },
    onAddPhoneNumbers(e) {
      e.stopPropagation();
      e.preventDefault();

      // disable textarea and add loading spinner (in case there's a lot of data incoming from a paste event)
      e.target.disabled = true;
      const l = document.createElement('div');
      l.classList.add('spinner-border');
      l.style.position = 'absolute';
      e.target.parentElement.appendChild(l);

      // detect whether it's a paste event or input event
      // and fetch the data accordingly.
      let data = '';
      if (e instanceof ClipboardEvent) {
        data = e.clipboardData.getData('text');
      } else if (e instanceof InputEvent) {
        data = e.target.value;
      }

      // validate and format data
      const matches = [ ...(data || '').matchAll(/(\+?[0-9]+\n?)/g) ].map(([ match ]) => {
        let result = match;
        if (!result.startsWith('+')) {
          result = `+${match}`;
        }

        return result.replace(/\n+/g, '\n');
      });
      e.target.value = matches.join('');

      // cleanup
      e.target.disabled = false;
      e.target.parentElement.removeChild(l);
      e.target.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px 0 10px 0;
  justify-content: center;
}

span {
  font-size: 1.2rem;
}

.card-header {
  cursor: pointer;
}
</style>
<style lang="scss">
.error-list {
  overflow: auto;
  max-height: 50vh;
  white-space: nowrap;
  background-color: #ffd2d2;
}

.modal {
  i.feather {
    width: 26px;
    height: 26px;
  }

  .modal-header {
    i.feather {
      color: green;
    }
  }

  .error-toolbar {
    i.feather {
      color: $accent-color;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

#sms-area {
  width: 100%;
}
</style>
